import React from 'react';
import { NavLink } from 'react-router-dom';
import ER from '../components/lg1.png';
import SC1 from '../components/top-file-unscreen.20d5e8ce.gif';
import SC2 from '../components/fire-unscreen.d8155b91.gif';
import SC3 from '../components/premium-unscreen.3caed705.gif';
const Header = () => {
  return (
    <React.Fragment>
      <img src={ER} style={{width: "110px"}}/>
      <div className="nav-link">
        <NavLink to="/top" activeClassName="active" style={{backgroundColor: "orange", padding: "5px", margin: "5px", cursor: "pointer", borderRadius: "40px", textDecoration: "none"}}>
          <img src={SC1} style={{width: "60px"}}/>Top Stories
        </NavLink>
        <NavLink to="/new" activeClassName="active" style={{backgroundColor: "orange", padding: "5px", margin: "5px", cursor: "pointer", borderRadius: "40px", textDecoration: "none"}}>
           <img src={SC2} style={{width: "60px"}}/>Latest Stories
        </NavLink>
        <NavLink to="/best" activeClassName="active" style={{backgroundColor: "orange", padding: "5px", margin: "5px", cursor: "pointer", borderRadius: "40px", textDecoration: "none"}}>
        <img src={SC3} style={{width: "60px"}}/>Best Stories
        </NavLink>
      </div>
    </React.Fragment>
  );
};

export default Header;
